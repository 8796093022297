body {
  overscroll-behavior-y: none;
  font-size: 16px;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

img {
  max-width: 100%;
}

textarea,
input {
  appearance: none;
  outline: none;
  border: none;
  box-shadow: none;
  vertical-align: top;
  text-indent: 0;
  padding: 0;
}

input[type="radio"] {
  appearance: radio;
}
input[type="checkbox"] {
  appearance: checkbox;
}
